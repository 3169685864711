import React from 'react';
import PropTypes from 'prop-types';

import CopyToClipboardButton from './CopyToClipboardButton';
import styles from '../styles/Entry.module.css';

function EntryContainer(props) {
  const {
    children,
    includeCopyButton,
    errorMessage,
    value,
  } = props;
  return (
    <div className={styles.entryContainer}>
      <div className={styles.innerEntryContainer}>
        {children}
        {includeCopyButton ? <CopyToClipboardButton value={value} /> : null}
      </div>
      {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
    </div>
  );
}

EntryContainer.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string,
  includeCopyButton: PropTypes.bool,
  errorMessage: PropTypes.string,
};

EntryContainer.defaultProps = {
  value: '',
  includeCopyButton: false,
  errorMessage: '',
};

export function Input(props) {
  const {
    value,
    disabled,
    id,
    onChange,
    includeCopyButton,
    errorMessage,
  } = props;

  return (
    <EntryContainer value={value} includeCopyButton={includeCopyButton} errorMessage={errorMessage}>
      <input
        id={id}
        type="text"
        disabled={disabled ? 'disabled' : ''}
        className={`${styles.entry} ${errorMessage ? styles.inputError : ''}`}
        value={value}
        onChange={onChange}
      />
    </EntryContainer>
  );
}

Input.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  includeCopyButton: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Input.defaultProps = {
  disabled: false,
  value: '',
  onChange: () => {},
  includeCopyButton: false,
  errorMessage: '',
};

export function Textarea(props) {
  const {
    value,
    disabled,
    id,
    onChange,
    includeCopyButton,
    rows,
    cols,
    placeholder,
    errorMessage,
  } = props;

  return (
    <EntryContainer value={value} includeCopyButton={includeCopyButton} errorMessage={errorMessage}>
      <textarea
        id={id}
        className={`${styles.entry} ${errorMessage ? styles.inputError : ''}`}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled ? 'disabled' : ''}
      />
    </EntryContainer>
  );
}

Textarea.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  includeCopyButton: PropTypes.bool,
  rows: PropTypes.string,
  cols: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
};

Textarea.defaultProps = {
  disabled: false,
  value: '',
  onChange: () => {},
  includeCopyButton: false,
  rows: '4',
  cols: '50',
  placeholder: '',
  errorMessage: '',
};
