import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Ulid, Uuid } from 'id128';
import * as Sentry from '@sentry/react';

import ActionButton from './components/ActionButton';
import { Box, BoxGroup } from './components/Box';
import { Input, Textarea } from './components/Entry';
import logo from './images/ulid-logo.png';
import styles from './styles/App.module.css';

Sentry.init({
  dsn: 'https://dcd5e425120547d09ccfd8fbc2f0aa04@o4504547955376128.ingest.sentry.io/4504547965403136',

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

// This is necessary due to https://github.com/aarondcohen/id128/issues/29.
// If that issue is fixed, we can remove the following 3 lines and add Uuid4 to the import above.
const Uuid4 = Uuid.versioned_ids.find(
  (version) => version.type.VARIANT === 1 && version.type.VERSION === 4,
);

function GenerateRandomUlidBox() {
  const [singleRandomUlid, setSingleRandomUlid] = useState('');
  const [singleRandomUlidUuid, setSingleRandomUlidUuid] = useState('');

  const generateRandomSingleUlid = () => {
    const randUlid = Ulid.generate();
    setSingleRandomUlid(randUlid.toCanonical());
    setSingleRandomUlidUuid(Uuid4.construct(randUlid.bytes).toCanonical());
  };

  // Generate a random ulid on load
  useEffect(() => {
    generateRandomSingleUlid();
  }, []);

  return (
    <Box title="Generate a random ULID">
      <BoxGroup>
        <label htmlFor="single-random-ulid">
          Generate a random ULID:
        </label>
        <Input id="single-random-ulid" value={singleRandomUlid} includeCopyButton disabled />
        <div className={styles.actionButtonContainer}>
          <ActionButton onClick={generateRandomSingleUlid}>Generate</ActionButton>
        </div>
      </BoxGroup>
      <BoxGroup>
        <label htmlFor="single-random-ulid-uuid">
          As UUID v4:
        </label>
        <Input id="single-random-ulid-uuid" value={singleRandomUlidUuid} includeCopyButton disabled />
      </BoxGroup>
    </Box>
  );
}

function UuidToUlidBox() {
  const [inputUuid, setInputUuid] = useState('');
  const [uuidToUlidResult, setUuidToUlidResult] = useState('');
  const [uuidToUlidResultTimestamp, setUuidToUlidResultTimestamp] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const convertUuidToUlid = () => {
    try {
      const ulid = Ulid.fromRaw(Uuid4.fromCanonical(inputUuid).toRaw());
      setUuidToUlidResultTimestamp(ulid.time.toISOString());
      setUuidToUlidResult(ulid.toCanonical());
    } catch {
      setErrorMessage('The text you entered is not a valid UUID.');
    }
  };

  const onInputChanged = (e) => {
    setInputUuid(e.target.value);
    setErrorMessage('');
  };

  return (
    <Box title="UUID to ULID">
      <BoxGroup>
        <label htmlFor="uuid-to-ulid">
          UUID v4:
        </label>
        <Input
          id="uuid-to-ulid"
          onChange={onInputChanged}
          value={inputUuid}
          errorMessage={errorMessage}
          includeCopyButton
        />
        <div className={styles.actionButtonContainer}>
          <ActionButton onClick={convertUuidToUlid}>Convert</ActionButton>
        </div>
      </BoxGroup>
      <BoxGroup>
        <label htmlFor="uuid-to-ulid-result">
          ULID:
        </label>
        <Input id="uuid-to-ulid-result" type="text" value={uuidToUlidResult} includeCopyButton disabled />
      </BoxGroup>
      <BoxGroup>
        <label htmlFor="uuid-to-ulid-result-timestamp">
          Timestamp:
        </label>
        <Input id="uuid-to-ulid-result-timestamp" value={uuidToUlidResultTimestamp} includeCopyButton disabled />
      </BoxGroup>
    </Box>
  );
}

function UlidToUuidBox() {
  const [inputUlid, setInputUlid] = useState('');
  const [ulidToUuidResult, setUlidToUuidResult] = useState('');
  const [ulidToUuidResultTimestamp, setUlidToUuidResultTimestamp] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const convertUlidToUuid = () => {
    try {
      const ulid = Ulid.fromCanonical(inputUlid);
      const uuid = Uuid4.fromRaw(ulid.toRaw()).toCanonical();
      setUlidToUuidResultTimestamp(ulid.time.toISOString());
      setUlidToUuidResult(uuid);
    } catch {
      setErrorMessage('The text you entered is not a valid ULID.');
    }
  };

  const onInputChanged = (e) => {
    setInputUlid(e.target.value);
    setErrorMessage('');
  };

  return (
    <Box title="ULID to UUID">
      <BoxGroup>
        <label htmlFor="ulid-to-uuid">
          ULID:
        </label>
        <Input
          id="ulid-to-uuid"
          onChange={onInputChanged}
          value={inputUlid}
          errorMessage={errorMessage}
          includeCopyButton
        />
        <div className={styles.actionButtonContainer}>
          <ActionButton onClick={convertUlidToUuid}>Convert</ActionButton>
        </div>
      </BoxGroup>
      <BoxGroup>
        <label htmlFor="ulid-to-uuid-result">
          UUID v4:
        </label>
        <Input id="ulid-to-uuid-result" value={ulidToUuidResult} includeCopyButton disabled />
      </BoxGroup>
      <BoxGroup>
        <label htmlFor="ulid-to-uuid-result-timestamp">
          Timestamp:
        </label>
        <Input id="ulid-to-uuid-result-timestamp" value={ulidToUuidResultTimestamp} includeCopyButton disabled />
      </BoxGroup>
    </Box>
  );
}

function MassConversionBox() {
  const [massConversionUuidInput, setMassConversionUuidInput] = useState('');
  const [massConversionUlidOutput, setMassConversionUlidOutput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const massConvertUuidToUlid = () => {
    try {
      const uuids = massConversionUuidInput.split(',');
      const ulids = uuids.reduce((output, uuid) => {
        const ulid = Ulid.fromRaw(Uuid4.fromCanonical(uuid.trim()).toRaw());
        const outputString = `${ulid.toCanonical()},${ulid.time.toISOString()}`;
        if (output) {
          return `${output}\n${outputString}`;
        }
        return outputString;
      }, '');
      setMassConversionUlidOutput(ulids);
    } catch {
      setErrorMessage('The text you entered is not a valid comma-separated list of UUIDs.');
    }
  };

  const onInputChanged = (e) => {
    setMassConversionUuidInput(e.target.value);
    setErrorMessage('');
  };

  return (
    <Box title="Mass convert UUIDs to ULIDs">
      <BoxGroup>
        <label htmlFor="mass-convert-uuids">
          UUIDs v4:
        </label>
        <Textarea
          id="mass-convert-uuids"
          placeholder="Enter a comma-separated list of UUIDs."
          onChange={onInputChanged}
          value={massConversionUuidInput}
          errorMessage={errorMessage}
          includeCopyButton
        />
        <div className={styles.actionButtonContainer}>
          <ActionButton onClick={massConvertUuidToUlid}>Convert</ActionButton>
        </div>
      </BoxGroup>
      <BoxGroup>
        <label htmlFor="mass-convert-ulids">
          ULIDs:
        </label>
        <Textarea
          id="mass-convert-ulids"
          disabled
          value={massConversionUlidOutput}
          includeCopyButton
        />
      </BoxGroup>
    </Box>
  );
}

function App() {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="ULID" height="100" />
      </div>
      <ReactTooltip />
      <h1>Tools for working with ULIDs</h1>
      <div className={styles.contentContainer}>
        <GenerateRandomUlidBox />
        <UuidToUlidBox />
        <UlidToUuidBox />
        <MassConversionBox />
      </div>
      <footer>
        <a href="mailto:ulidtools@henri.pub">Contact</a>
        <br />
        <br />
        <span>
          Part of Proteus Technologies Ltd.
          |&nbsp;
          <a href="https://www.maildrop.dev">MailDrop</a>
        </span>
      </footer>
    </div>
  );
}

export default App;
