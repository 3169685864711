import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles/ActionButton.module.css';

function ActionButton(props) {
  const { children, onClick } = props;

  return <button type="button" className={styles.actionButton} onClick={onClick}>{children}</button>;
}

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ActionButton;
