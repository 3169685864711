import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles/Box.module.css';

export function Box(props) {
  const { title, children } = props;
  return (
    <div className={`${styles.aGenerator} ${styles.borderBox}`}>
      <div className={styles.legend}>{title}</div>
      <br />
      {children}
    </div>
  );
}

Box.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export function BoxGroup(props) {
  const { children } = props;
  return (
    <div className={styles.boxGroup}>
      {children}
    </div>
  );
}

BoxGroup.propTypes = {
  children: PropTypes.node.isRequired,
};
