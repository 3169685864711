import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import copyIcon from '../images/copy-icon.png';
import styles from '../styles/CopyToClipboardButton.module.css';

function CopyToClipboardButton(props) {
  const { value } = props;

  return (
    <CopyToClipboard text={value}>
      <img data-tip="Copy" src={copyIcon} className={styles.copyIcon} alt="Copy" />
    </CopyToClipboard>
  );
}

CopyToClipboardButton.propTypes = {
  value: PropTypes.string,
};

CopyToClipboardButton.defaultProps = {
  value: '',
};

export default CopyToClipboardButton;
